<template>
  <div>
    <div class="text-right mb-1">
      <add-resource
        resource-name="global-CalendarTopicController"
        add-route-name="topic-add"
      />
    </div>

    <b-row>
      <b-col>
        <b-card header="Search">
          <b-form-row>
            <b-col
              md="4"
              class="mb-1 mb-md-0"
            >
              <search
                placeholder="Title, Speaker"
                :search-query.sync="searchCriteria.name"
                @refresh="refreshTable"
              />
            </b-col>

            <b-col md="4">
              <inpo-select :inpo-id.sync="searchCriteria.entityId" />
            </b-col>

            <b-col md="4">
              <live-auto-suggest
                :live-id.sync="searchCriteria.liveId"
                :entity-id="searchCriteria.entityId"
              />
            </b-col>

            <b-col
              md="2"
              class="mt-1 mt-md-0"
            >
              <search-button @refresh="refreshTable" />
            </b-col>
          </b-form-row>
        </b-card>
      </b-col>
    </b-row>

    <b-row align-h="center">
      <div
        v-if="loading"
        class="loader"
      />
    </b-row>

    <topics-table
      ref="parentTable"
      v-bind="{
        topicsProvider,pagination
      }"
    />

    <pagination
      :total-rows="pagination.totalRows"
      :per-page="pagination.perPage"
      :current-page.sync="pagination.currentPage"
    />
  </div>
</template>
<script>
import TopicsTable from '@/common/components/TopicsCalendar/TopicsTable.vue'
import Pagination from '@/common/components/common/Table/Pagination.vue'
import AddResource from '@/common/components/common/Table/AddResource.vue'
import Search from '@/common/components/common/Table/Search.vue'
import SearchButton from '@/common/components/common/Table/SearchButton.vue'
import InpoSelect from '@/common/components/common/FormInputs/InpoSelect.vue'
import LiveAutoSuggest from '@/common/components/common/FormInputs/LiveAutoSuggest.vue'
import paginationData from '@/common/compositions/common/paginationData'

export default {
  name: 'TopicsCalendarList',
  components: {
    TopicsTable,
    Pagination,
    AddResource,
    Search,
    SearchButton,
    InpoSelect,
    LiveAutoSuggest,
  },
  data() {
    return {
      loading: true,
      searchCriteria: {
        name: '',
        entityId: '',
        liveId: '',
      },
    }
  },
  setup() {
    const { pagination } = paginationData()
    return { pagination }
  },
  methods: {
    topicsProvider() {
      return this.$activities.get('/internalops/weekly/calendar/topics', {
        params: {
          key: this.searchCriteria.name,
          entity: this.searchCriteria.entityId,
          live: this.searchCriteria.liveId,
        },
      }).then(res => {
        const topics = res.data.data
        return topics || []
      }).catch(() => [])
        .finally(() => { this.loading = false })
    },
    refreshTable() {
      this.$refs.parentTable.$refs.table.refresh()
    },
  },
}
</script>
<style lang="scss">

</style>
