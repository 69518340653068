<template>
  <b-card>
    <b-table
      ref="table"
      :no-provider-sorting="true"
      :items="topicsProvider"
      :fields="tableColumns"
      :current-page="pagination.currentPage"
      :per-page="pagination.perPage"
      class="position-relative"
      responsive
      empty-text="No Topics found"
      show-empty
    >
      <template #cell(actions)="{item}">
        <b-dropdown
          variant="link"
          no-caret
        >
          <template #button-content>
            <feather-icon
              icon="MoreVerticalIcon"
              size="16"
              class="align-middle text-body"
            />
          </template>
          <b-dropdown-item
            v-if="$can('show','global-CalendarTopicController')"
            :to="{ name: 'topic-view', params: { id: item.id} }"
          >
            <feather-icon icon="FileTextIcon" />
            <span class="align-middle ml-50"> View </span>
          </b-dropdown-item>
          <b-dropdown-item
            v-if="$moment().isBefore(item.end_time) && $can('update','global-CalendarTopicController')"
            :to="{ name: 'topic-edit', params: { id: item.id} }"
          >
            <feather-icon icon="EditIcon" />
            <span> Edit </span>
          </b-dropdown-item>
          <b-dropdown-item
            v-if="$can('delete','global-CalendarTopicController')"
            @click="deleteRecord(deleteEndPoint,item.id)"
          >
            <feather-icon icon="TrashIcon" />
            <span
              class="align-middle ml-50"
            >Delete</span>
          </b-dropdown-item>
        </b-dropdown>
      </template>
    </b-table>
  </b-card>
</template>
<script>
import deleteTopic from '@/common/compositions/common/deleteRecord'

export default {
  name: 'TopicsTable',
  props: {
    topicsProvider: { type: Function, default: () => [] },
    pagination: { type: Object, default: () => {} },
  },
  data() {
    return {
      tableColumns: [
        { key: 'title' },
        { key: 'speaker' },
        { key: 'start_time', formatter: value => this.$moment(value).format('YYYY-MM-DD HH:mm') },
        { key: 'end_time', formatter: value => this.$moment(value).format('YYYY-MM-DD HH:mm') },
        { key: 'live' },
        { key: 'actions' },
      ],
      deleteEndPoint: `${process.env.VUE_APP_ACTIVITIES_BASE_URL}/internalops/weekly/calendar/topics/`,
    }
  },
  setup() {
    const { deleteRecord, table } = deleteTopic()
    return { deleteRecord, table }
  },
}
</script>
<style lang="scss">

</style>
